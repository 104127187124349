import React, { useCallback, useEffect, useState } from 'react';
import Navbar from '../navbar';
import { LineChart, PieChart } from '@mui/x-charts';
import axios from 'axios';
import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import es_ES from 'rsuite/locales/es_ES';
import { getSummaryGraph } from '../../graphql/Summary';
import { Grid, Typography, Box, Container, Icon } from '@mui/material';
import DashboardItems from './DashboardItems';
import { isMobile, isTablet } from 'react-device-detect';
import './dashboard.css';
import '../../styles/main.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Dashboard() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [profits, setProfits] = useState(null);
  const [clientsAmount, setClientsAmount] = useState(null);
  const [myTotal, setMyTotal] = useState(null);
  const [owners, setOwners] = useState(null);
  const [reservations, setReservations] = useState(null);
  const [percentageData, setPercentageData] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [outerRadius, setOuterRadius] = useState(150);
  const [lineWidth, setLineWidth] = useState(650);
  const [spacePie, setSpacePie] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [filteredOwners, setFilteredOwners] = useState([]);

  const { width } = useWindowDimensions();
  const handleDateChange = (date) => {
    if (date && date.length === 2) {
      const startTimestampMilliseconds = date?.[0]
        ? new Date(date[0]).getTime()
        : null;
      const endTimestampMilliseconds = date[1]
        ? new Date(date[1]).getTime()
        : null;

      const startTimestamp = startTimestampMilliseconds
        ? Math.floor(startTimestampMilliseconds / 1000)
        : null;
      const endTimestamp = endTimestampMilliseconds
        ? Math.floor(endTimestampMilliseconds / 1000)
        : null;

      setStartDate(startTimestamp);
      setEndDate(endTimestamp);

      sessionStorage.setItem('startDate', startTimestamp);
      sessionStorage.setItem('endDate', endTimestamp);
    } else {
      setStartDate(null);
      setEndDate(null);
      sessionStorage.removeItem('startDate');
      sessionStorage.removeItem('endDate');
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchName(value);

    const filtered = owners.filter((owner) =>
      owner.name.toLowerCase().includes(value)
    );

    setFilteredOwners(filtered);
  };

  const getQuery = () => {
    const userDataFromStorage = JSON.parse(localStorage.getItem('user'));
    const variables = {
      sellerId: userDataFromStorage.id,
      startDate: sessionStorage.getItem('startDate'),
      endDate: sessionStorage.getItem('endDate')
    };

    const apiUrl = 'https://rent-car-backend-ecxw.onrender.com/api/graphql';
    const query = getSummaryGraph(variables);

    axios
      .post(apiUrl, {
        query: query.query,
        variables: query.variables
      })
      .then((response) => {
        const data = response?.data?.data?.Summary?.result;
        setProfits(data ? data.profits : null);
        setClientsAmount(data ? data.clientsAmount : null);
        setMyTotal(data ? data.myTotal : null);
        setReservations(data ? data.reservations : null);
        setOwners(data ? data.owners : null);
        setFilteredOwners(data ? data.owners : null);

        const chartDataFromApi = data ? data.chart : [];
        const labels = chartDataFromApi.map((entry) => entry?.month);
        const values = chartDataFromApi.map((entry) => entry?.amount);
        setChartData({
          labels: labels,
          datasets: [
            {
              label: 'Reservaciones',
              data: values,
              fill: true,
              borderColor: 'rgb(50, 200, 250)',
              tension: 0.1
            }
          ]
        });

        const chartPercentageFromApi = data ? data.percentage : [];
        setPercentageData(chartPercentageFromApi);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    getQuery();
  }, [startDate, endDate]);
  const transformedPercentageData = percentageData.map((item) => ({
    label: item.name,
    value: item.percentage
  }));

  const TOTAL = transformedPercentageData
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const updateOuterRadius = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 400) {
      setSpacePie(150);
    } else if (screenWidth < 500) {
      setOuterRadius(125);
      setLineWidth(screenWidth - 45);
      setSpacePie(200);
    } else if (screenWidth < 600) {
      setSpacePie(250);
      setOuterRadius(150);
      setLineWidth(screenWidth - 45);
    } else if (screenWidth < 800) {
      setSpacePie(false);
      setLineWidth(screenWidth - 45);
    } else if (screenWidth < 900) {
      setSpacePie(false);
      setLineWidth(screenWidth - 45);
    } else if (screenWidth < 960) {
      setSpacePie(false);
      setOuterRadius(150);
      setLineWidth(450);
    } else {
      setSpacePie(false);
      setOuterRadius(150);
      setLineWidth(550);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateOuterRadius);
    };

    updateOuterRadius();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [updateOuterRadius]);

  return (
    <Box>
      <Navbar />
      <Container>
        <br />
        <br />
        <br />
        <Box textAlign="center" mt={4} mb={4}>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            fontWeight="bold"
          >
            Bienvenido a tu Dashboard
          </Typography>
          <br />
          <br />
          <CustomProvider locale={es_ES}>
            <DateRangePicker
              size="lg"
              block
              showOneCalendar
              locale="es_ES"
              placeholder="Selecciona fechas"
              onChange={handleDateChange}
            />
          </CustomProvider>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardItems type="item1">
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                fontWeight="bold"
              >
                Total de ventas
              </Typography>
              ${profits}
            </DashboardItems>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardItems type="item2">
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                fontWeight="bold"
              >
                Mi Total
              </Typography>
              ${myTotal}
            </DashboardItems>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardItems type="item3">
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                fontWeight="bold"
              >
                Total de clientes
              </Typography>
              {clientsAmount}
            </DashboardItems>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardItems type="item4">
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                fontWeight="bold"
              >
                Total de reservas
              </Typography>
              {reservations}
            </DashboardItems>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              textAlign: 'center'
            }}
          >
            <Grid item xs={12} md={6} style={{ marginTop: '10%' }}>
              <LineChart
                xAxis={[{ scaleType: 'point', data: chartData.labels }]}
                series={chartData.datasets}
                width={lineWidth}
                height={400}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                textAlign: 'center',
                flexDirection: 'column',
                padding: '20px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginTop: '50px',
                  paddingTop: '20%',
                  marginRight: '20%',
                  zIndex: 99999999999
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  fontWeight="bold"
                >
                  Vendedores
                </Typography>
              </div>
              <PieChart
                axisHighlight
                series={[
                  {
                    data: transformedPercentageData,
                    arcLabel: getArcLabel,
                    innerRadius: 70,
                    outerRadius: outerRadius,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    ...(spacePie ? { cx: spacePie } : {})
                    // faded: {
                    //   innerRadius: 30,
                    //   additionalRadius: -30,
                    //   color: 'gray'
                    // }
                  }
                ]}
                slotProps={{
                  legend: {
                    labelStyle: {
                      tableLayout: 'fixed'
                    },
                    direction: 'row',
                    position: {
                      horizontal: 'middle',
                      vertical: 'bottom'
                    }
                  }
                }}
                // width={700}
                height={500}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              textAlign: 'center'
            }}
          >
            <Grid item xs={12} md={12}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                fontWeight="bold"
              >
                Ganancias de propietarios
              </Typography>
              <div className="formGroup">
                <input
                  type="text"
                  id="searchName"
                  name="searchName"
                  value={searchName}
                  onChange={(data) => handleSearch(data)}
                  className="input"
                  placeholder="Nombre"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    width: '100%'
                  }}
                >
                  <table className="content-table">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        {width > 768 && <th>Correo</th>}
                        {width > 768 && <th>Número</th>}
                        <th>Pagar</th>
                        {width > 768 && <th>Ganancia</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredOwners?.map((owner) => (
                        <>
                          <tr key={owner._id}>
                            <td>{owner?.name}</td>
                            {width > 768 && <td>{owner?.email}</td>}
                            {width > 768 && <td>{owner?.number}</td>}
                            <td>${Math.round(owner?.amountToPay)}</td>
                            {width > 768 && (
                              <td>${Math.round(owner?.totalPrice)}</td>
                            )}
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Dashboard;
