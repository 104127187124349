import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { queryToGraph } from '../helper/graphRequest';
import { getVehicleGraph } from '../graphql/Vehicle';
import _ from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getTransmissionGraph } from '../graphql/Transmission';
import { getFuelGraph } from '../graphql/Fuel';
import { roles } from '../constants/system';
import { getTypeVehicleGraph } from '../graphql/TypeVehicle';

// const baseURL = 'https://carrental-9ijm.onrender.com'
const baseURL = 'https://rent-car-backend-ecxw.onrender.com/api';

const initialState = {
  cars: [],
  transmissionTypes: [],
  fuelTypes: [],
  status: 'idle',
  isLoading: false,
  isModalOpen: false,
  typeVehicle: [],
  error: ''
};

export const fetchCars = createAsyncThunk('cars/fetchCars', async (params) => {
  const response = await queryToGraph(getVehicleGraph(params)); //fetch(`${baseURL}/cars`);
  if (_.get(response, 'Vehicle.isSuccess', false)) {
    return _.get(response, 'Vehicle.result', []);
  }
  return _.get(response, 'Vehicle.message', '');
});

export const fetchTransmissionTypes = createAsyncThunk(
  'cars/fetchTransmissionTypes',
  async () => {
    const response = await queryToGraph(getTransmissionGraph()); //fetch(`${baseURL}/cars`);
    if (_.get(response, 'Transmission.isSuccess', false)) {
      return _.get(response, 'Transmission.result', []);
    }
    return _.get(response, 'Transmission.message', '');
  }
);

export const fetchFuelTypes = createAsyncThunk(
  'cars/fetchFuelTypes',
  async () => {
    const response = await queryToGraph(getFuelGraph()); //fetch(`${baseURL}/cars`);
    if (_.get(response, 'Fuels.isSuccess', false)) {
      return _.get(response, 'Fuels.result', []);
    }
    return _.get(response, 'Fuels.message', '');
  }
);

export const fetchTypeVehicle = createAsyncThunk(
  'cars/fetchTypeVehicle',
  async () => {
    const response = await queryToGraph(getTypeVehicleGraph()); //fetch(`${baseURL}/cars`);
    if (_.get(response, 'TypeVehicle.isSuccess', false)) {
      localStorage.setItem(
        'typeVehicle',
        JSON.stringify(_.get(response, 'TypeVehicle.result', []))
      );
      return _.get(response, 'TypeVehicle.result', []);
    }
    return _.get(response, 'TypeVehicle.message', '');
  }
);

export const addCar = createAsyncThunk(
  'cars/addCar',
  async ({
    idCar,
    brand,
    color,
    year,
    imgCar,
    is4x4,
    fuelId,
    transmissionId,
    circulationCard,
    amountDoor,
    amountSeat,
    model,
    price,
    priceOwner,
    name,
    email,
    number,
    typeVehicle,
    insurance,
    deductible,
    nPolicy,
    nAttendance,
    ownerVehicle
  }) => {
    const formData = new FormData();
    formData.append('idCar', idCar);
    formData.append('brand', brand);
    formData.append('color', color);
    formData.append('year', year);
    formData.append('file', imgCar);
    formData.append('is4x4', is4x4);
    formData.append('fuelId', fuelId);
    formData.append('transmissionId', transmissionId);
    formData.append('circulationCard', circulationCard);
    formData.append('amountDoor', amountDoor);
    formData.append('amountSeat', amountSeat);
    formData.append('model', model);
    formData.append('price', price);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('number', number);
    formData.append('typeVehicle', typeVehicle);
    formData.append('priceOwner', priceOwner);
    formData.append('rolId', roles.vehicleOwner);
    formData.append('insurance', insurance);
    formData.append('deductible', deductible);
    formData.append('nPolicy', nPolicy);
    formData.append('nAttendance', nAttendance);
    formData.append('ownerVehicle', ownerVehicle);

    const response = await axios.post(`${baseURL}/vehicle`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
);

export const updateCar = createAsyncThunk(
  'cars/updateCar',
  async ({
    id,
    idCar,
    brand,
    color,
    year,
    imgCar,
    is4x4,
    fuelId,
    transmissionId,
    circulationCard,
    amountDoor,
    amountSeat,
    model,
    price,
    priceOwner,
    typeVehicle,
    insurance,
    deductible,
    nPolicy,
    nAttendance
  }) => {
    const formData = new FormData();
    formData.append('idCar', idCar);
    formData.append('brand', brand);
    formData.append('color', color);
    formData.append('year', year);
    formData.append('is4x4', is4x4);
    formData.append('fuelId', fuelId?._id ? fuelId?._id : fuelId);
    formData.append(
      'transmissionId',
      transmissionId?._id ? transmissionId?._id : transmissionId
    );
    formData.append('circulationCard', circulationCard);
    formData.append('amountDoor', amountDoor);
    formData.append('amountSeat', amountSeat);
    formData.append('model', model);
    formData.append('price', price);
    formData.append('priceOwner', priceOwner);
    formData.append(
      'typeVehicle',
      typeVehicle?._id ? typeVehicle?._id : typeVehicle
    );
    formData.append('insurance', insurance);
    formData.append('deductible', deductible);
    formData.append('nPolicy', nPolicy);
    formData.append('nAttendance', nAttendance);
    if (imgCar) formData.append('file', imgCar);

    const response = await axios.patch(`${baseURL}/vehicle/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
);

export const deleteCar = createAsyncThunk(
  'car/deleteCar',
  async ({ carId }) => {
    await fetch(`${baseURL}/vehicle/${carId}`, {
      method: 'DELETE'
    });
    return carId;
  }
);

const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    // Add a reducer to reset status after a certain time
    resetStatus: (state) => ({
      ...state,
      status: 'idle'
    }),
    openModal: (state) => ({
      ...state,
      isModalOpen: true
    }),
    closeModal: (state) => ({
      ...state,
      isModalOpen: false
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCars.pending, (state) => ({
        ...state,
        status: 'fetching',
        isLoading: true
      }))
      .addCase(fetchCars.fulfilled, (state, action) => ({
        ...state,
        status: 'fetched',
        isLoading: false,
        cars: action.payload
      }))
      .addCase(fetchCars.rejected, (state, action) => ({
        ...state,
        status: 'error-fetching',
        isLoading: false,
        error: action.error.message
      }))
      .addCase(fetchTransmissionTypes.pending, (state) => ({
        ...state,
        status: 'fetching-transmission',
        isLoading: true
      }))
      .addCase(fetchTransmissionTypes.fulfilled, (state, action) => ({
        ...state,
        status: 'fetched-transmission',
        isLoading: false,
        transmissionTypes: action.payload
      }))
      .addCase(fetchTransmissionTypes.rejected, (state, action) => ({
        ...state,
        status: 'error-fetching-transmission',
        isLoading: false,
        error: action.error.message
      }))
      .addCase(fetchFuelTypes.pending, (state) => ({
        ...state,
        status: 'fetching-fuels',
        isLoading: true
      }))
      .addCase(fetchFuelTypes.fulfilled, (state, action) => ({
        ...state,
        status: 'fetched-fuels',
        isLoading: false,
        fuelTypes: action.payload
      }))
      .addCase(fetchFuelTypes.rejected, (state, action) => ({
        ...state,
        status: 'error-fetching-fuels',
        isLoading: false,
        error: action.error.message
      }))
      .addCase(fetchTypeVehicle.pending, (state) => ({
        ...state,
        status: 'fetching-typeVehicle',
        isLoading: true
      }))
      .addCase(fetchTypeVehicle.fulfilled, (state, action) => ({
        ...state,
        status: 'fetched-typeVehicle',
        isLoading: false,
        typeVehicle: action.payload
      }))
      .addCase(fetchTypeVehicle.rejected, (state, action) => ({
        ...state,
        status: 'error-fetching-typeVehicle',
        isLoading: false,
        error: action.error.message
      }))
      .addCase(addCar.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'adding'
      }))
      .addCase(addCar.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          // Check if there are no errors
          toast.success('Nuevo auto agregado!');
          return {
            ...state,
            status: 'added successfully',
            isLoading: false,
            isModalOpen: false,
            cars: [action.payload.result, ...state.cars],
            error: '' // Clear any previous errors
          };
        }

        toast.error(`Error: ${action.payload.message}`);
        return {
          ...state,
          status: 'error-adding',
          isLoading: false,
          error: action.payload.message
        };
      })
      .addCase(addCar.rejected, (state) => ({
        ...state,
        status: 'error-adding',
        isLoading: false
        // error: action.payload.errors,
      }))
      .addCase(updateCar.pending, (state) => ({
        ...state,
        isLoading: true,
        status: 'updating'
      }))
      .addCase(updateCar.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          // Check if there are no errors
          toast.success('Auto actualizado con éxito!');
          const newCars = state.cars.map((car) => {
            if (car._id === action.payload.result._id)
              return action.payload.result;

            return car;
          });
          return {
            ...state,
            status: 'updated successfully',
            isLoading: false,
            isModalOpen: false,
            cars: newCars,
            error: '' // Clear any previous errors
          };
        }

        toast.error(`Error: ${action.payload.message}`);
        return {
          ...state,
          status: 'error-updating',
          isLoading: false,
          error: action.payload.message
        };
      })
      .addCase(updateCar.rejected, (state) => ({
        ...state,
        status: 'error-updating',
        isLoading: false
        // error: action.payload.errors,
      }))
      .addCase(deleteCar.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(deleteCar.fulfilled, (state, action) => ({
        ...state,
        status: 'deleted successfully',
        isLoading: false,
        cars: state.cars.filter((car) => car._id !== action.payload)
      }))
      .addCase(deleteCar.rejected, (state, action) => ({
        ...state,
        status: 'error-deleting',
        isLoading: false,
        error: action.payload.errors
      }));
  }
});

export const { resetStatus, openModal, closeModal } = carsSlice.actions;
export default carsSlice.reducer;
