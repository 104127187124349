import React, { useEffect, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCar, fetchCars } from '../redux/carsSlice';
import {
  addReservationOrder,
  closeModal,
  openModal
} from '../redux/reservationOrdersSlice';
import '../styles/detail.css';
import Alert from './Alert';
import FormReservation from './Main/FormReservation';
import AppModal from './Modal';

const Detail = () => {
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem('user'));
  const isModalOpen = useSelector(
    (state) => state.reservationOrder.isModalOpen
  );
  const status = useSelector((state) => state.car.status);
  const isLoading = useSelector((state) => state.car.isLoading);
  const cars = useSelector((state) => state.car.cars);
  const car = cars.find((car) => car._id === id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchCars());
  }, [dispatch]);

  if (!car) {
    return <h1 className="heading">Loading Car Detail...Please Wait</h1>;
  }

  const handleOpenReserveModal = (e) => {
    handleOpenModal();
  };

  const handleReserve = ({
    clientId,
    startDate,
    endDate,
    sellerId,
    name,
    rolId,
    license,
    amount,
    amountDescription,
    idDocument,
    deposit
  }) => {
    dispatch(
      addReservationOrder({
        sellerId,
        clientId,
        vehicleId: id,
        startDate,
        endDate,
        name,
        rolId,
        license,
        amount,
        amountDescription,
        idDocument,
        deposit
      })
    );
  };

  const navigateToEdit = () => {
    navigate(`/cars/${id}/edit`);
  };

  const handleDeleteCar = async () => {
    await dispatch(deleteCar({ userId: userData.id, carId: id }));
    navigate('/');
  };
  return (
    <>
      <section id="detail">
        <header className="header">
          <IoIosArrowBack className="back-btn" onClick={() => navigate('/')} />
          <h2 className="header-title">{car.brand}</h2>
        </header>

        <div className="detail-container">
          <div className="banner">
            <div className="img-container">
              <img src={car.imgCar} alt={car.brand} className="img" />
            </div>
          </div>

          <div className="detail-wrapper">
            <div className="details">
              <div className="img-text">
                <div className="rating">
                  <AiOutlineUser className="user-icon" />
                  <div>
                    <p>{`Vendedor: ${userData?.name}`}</p>
                  </div>
                </div>
              </div>
              <div className="img-text">
                <div className="rating">
                  <AiOutlineUser className="user-icon" />
                  <div>
                    <p>{`Propietario: ${car?.vehicleOwner?.name}`}</p>
                  </div>
                </div>
              </div>

              <div className="features-table">
                <div className="feature-cell bold">
                  <span>Precio</span>
                </div>
                <div className="feature-cell">
                  <span>${car?.price}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Precio del dueño</span>
                </div>
                <div className="feature-cell">
                  <span>${car?.priceOwner}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Marca</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.brand}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Modelo</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.model}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Año</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.year}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Combustible</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.fuelId?.name}</span>
                </div>
                <div className="feature-cell bold">
                  <span>No. Puertas</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.amountDoor}</span>
                </div>
                <div className="feature-cell bold">
                  <span>No. Asientos</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.amountSeat}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Color</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.color}</span>
                </div>
                <div className="feature-cell bold">
                  <span>4x4</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.is4x4 ? 'Si' : 'No'}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Transmisión</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.transmissionId?.name}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Tipo de vehículo</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.typeVehicle?.name}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Aseguradora</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.insurance}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Deducible</span>
                </div>
                <div className="feature-cell">
                  <span>%{car?.deductible}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Número de póliza</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.nPolicy}</span>
                </div>
                <div className="feature-cell bold">
                  <span>Número de asistencia</span>
                </div>
                <div className="feature-cell">
                  <span>{car?.nAttendance}</span>
                </div>
              </div>
            </div>
            <div className="reserve-container">
              <button
                type="button"
                className="reserve-btn"
                onClick={handleOpenReserveModal}
              >
                Reservar
              </button>
              <button
                type="button"
                className="reserve-btn"
                onClick={navigateToEdit}
              >
                Editar
              </button>
              <button
                type="button"
                className="reserve-btn"
                onClick={() => setIsAlertVisible(true)}
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </section>
      {isModalOpen && (
        <AppModal onClose={handleCloseModal}>
          <FormReservation onSubmit={handleReserve} />
        </AppModal>
      )}
      {Boolean(isAlertVisible) && (
        <Alert
          title="Estás seguro que quieres eliminar este auto?"
          description="Esta acción es irreversible"
          type="delete"
          onCloseAlert={() => setIsAlertVisible(false)}
          primaryAction={handleDeleteCar}
          isLoading={isLoading && status === 'deleting'}
        />
      )}
    </>
  );
};

export default Detail;
