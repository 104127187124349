import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../Icons';
import Navbar from '../navbar';

import {
  fetchReservationOrders,
  deleteReservationOrder
} from '../../redux/reservationOrdersSlice';
import '../../styles/reservations.css';
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import EmptyImg from '../Icons/EmptyImg';

import { ToggleButton, Tooltip } from '@mui/material';
function Reservations() {
  const isLoading = useSelector((state) => state.seller.isLoading);
  const orders = useSelector((state) => state.reservationOrder.orders);
  const status = useSelector((state) => state.reservationOrder.status);
  const dispatch = useDispatch();
  const [ordersFiltered, setOrdersFiltered] = useState([]);

  const { width } = useWindowDimensions();

  const [selectedOrderReturn, setFormData] = useState({
    receivedBy: '',
    odometer: '',
    comments: '',
    file: ''
  });

  useEffect(() => {
    dispatch(fetchReservationOrders());
  }, [dispatch]);

  const handleSearch = (event) => {
    const searchedText = event.target?.value;
    const searchedOrders = orders?.filter(
      (order) =>
        order?.vehicleId?.idCar
          ?.toLowerCase()
          .includes(searchedText.toLowerCase()) ||
        order?.clientId?.name
          .toLowerCase()
          .includes(searchedText.toLowerCase()) ||
        order?.sellerId?.name
          .toLowerCase()
          .includes(searchedText?.toLowerCase())
    );

    setOrdersFiltered(searchedOrders);
  };

  const ordersToRender = ordersFiltered.length > 0 ? ordersFiltered : orders;

  if (isLoading && status === 'fetching') {
    return (
      <div className="container LoaderPageContainer">
        <div className="wrapper">
          <div className="loaderWrapper">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page_container">
      <Navbar />
      <div className="container">
        <div className="wrapper">
          <div className="titleWrapper">
            <h1 style={{ color: '#DC143C !important' }}>Lista de reservas</h1>
          </div>
          <input
            type="text"
            id="name"
            name="name"
            className="input searchInput"
            placeholder="Busca por nombre de cliente, vendedor, o placa de auto"
            onChange={handleSearch}
          />
          {!isLoading && status === 'success' && ordersToRender.length === 0 ? (
            <div style={{ maxWidth: `${width}px`, margin: '0 auto' }}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                <h2 className="heading" style={{ width: '100%' }}>
                  No se encontraron reservaciones
                </h2>
                <div>
                  <EmptyImg />
                </div>
              </div>
            </div>
          ) : (
            <table className="content-table">
              <thead>
                <tr>
                  <th>Placa</th>
                  {width > 768 && <th>Cliente</th>}
                  {width > 768 && <th>Vendedor</th>}
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {ordersToRender?.map(
                  (
                    {
                      _id: orderId,
                      vehicleId: car,
                      clientId: client,
                      sellerId: seller,
                      hasAlreadyReturn
                    },
                    index
                  ) => (
                    <>
                      <tr key={orderId}>
                        <td>{car?.idCar}</td>
                        {width > 768 && (
                          <td
                            style={
                              !client?.status ? { color: 'red' } : undefined
                            }
                          >
                            {client?.name}
                          </td>
                        )}
                        {width > 768 && (
                          <td
                            style={
                              !seller?.status ? { color: 'red' } : undefined
                            }
                          >
                            {seller?.name}
                          </td>
                        )}
                        <td>{hasAlreadyReturn ? 'Retornado' : 'Reservado'}</td>
                        <td>
                          <div className="iconsWrapper">
                            <Link to={`/reserved/${orderId}`}>
                              <Tooltip title="Ver Reserva" placement="top">
                                <ToggleButton
                                  style={{ backgroundColor: 'white' }}
                                  value="gallery"
                                  aria-label="Ver Reserva"
                                >
                                  <Icon name="eye" />
                                </ToggleButton>
                              </Tooltip>
                            </Link>
                          </div>
                          <br />
                          <div className="iconsWrapper">
                            <Tooltip title="Eliminar Reserva" placement="top">
                              <ToggleButton
                                style={{ backgroundColor: 'white' }}
                                value="gallery"
                                aria-label="Eliminar Reserva"
                                onClick={() =>
                                  dispatch(deleteReservationOrder(orderId))
                                }
                              >
                                <Icon name="delete" style={{ color: 'gray' }} />
                              </ToggleButton>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default Reservations;
