import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../Alert';
import Icon from '../Icons';
import AppModal from '../Modal';
import Navbar from '../navbar';
import Form from './Form';

import { roles } from '../../constants/system';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {
  addUser,
  closeModal,
  deleteUser,
  fetchUsers,
  openModal,
  updateUser
} from '../../redux/usersSlice';
import '../../styles/sellers.css';
import Loader from '../Loader';

function Clients() {
  const userData = JSON.parse(localStorage.getItem('user'));
  const users = useSelector((state) => state.user.users);
  const clients = users?.filter((user) => user.rolId?._id === roles.client);
  const isModalOpen = useSelector((state) => state.user.isModalOpen);
  const isLoading = useSelector((state) => state.user.isLoading);
  const status = useSelector((state) => state.user.status);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [selectedClientData, setSelectedClientData] = useState({
    _id: '',
    name: '',
    email: '',
    number: '',
    license: '',
    passport: '',
    idDocument: '',
    residenceAddress: '',
    address: ''
  });
  const [clientToDelete, setClientToDelete] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [clientsFiltered, setClientsFiltered] = useState([]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOpenCreateClientModal = () => {
    setSelectedClientData({
      _id: '',
      name: '',
      email: '',
      number: '',
      password: '',
      rolId: roles.client
    });
    setEditModal(false);
    handleOpenModal();
  };

  const handleOpenEditModal = (data) => {
    setSelectedClientData(data);
    handleOpenModal();
    setEditModal(true);
  };

  const handleEdit = (clientDetails) => {
    dispatch(updateUser(clientDetails));
  };

  const handleCreateClient = (clientDetails) => {
    dispatch(addUser(clientDetails));
  };

  const handleDeleteClient = () => {
    dispatch(deleteUser({ userId: clientToDelete }));
    setClientToDelete('');
  };

  const handleSearch = (event) => {
    const searchedText = event.target?.value;
    const searchedClients = clients?.filter(
      (client) =>
        client?.name.toLowerCase().includes(searchedText.toLowerCase()) ||
        client?.email.toLowerCase().includes(searchedText.toLowerCase()) ||
        client?.number.toLowerCase().includes(searchedText.toLowerCase())
    );

    setClientsFiltered(searchedClients);
  };

  const clientsToRender =
    clientsFiltered.length > 0 ? clientsFiltered : clients;

  if (isLoading && status === 'fetching') {
    return (
      <div className="container LoaderPageContainer">
        <div className="wrapper">
          <div className="loaderWrapper">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page_container">
      <Navbar />
      <div className="container">
        <div className="wrapper">
          <div className="titleWrapper">
            <h1 style={{ color: '#DC143C !important' }}>Lista de clientes</h1>
          </div>
          <div className="createButtonWrapper">
            <button
              style={{ marginBottom: '1rem' }}
              className="button"
              onClick={handleOpenCreateClientModal}
            >
              Añadir Cliente
            </button>
          </div>
          <input
            type="text"
            id="name"
            name="name"
            className="input searchInput"
            placeholder="Busca por nombre, email, o teléfono"
            onChange={handleSearch}
          />
          {!isLoading &&
          status === 'success' &&
          clientsToRender.length === 0 ? (
            <h2 className="heading" style={{ width: '100%' }}>
              No se encontraron clientes
            </h2>
          ) : (
            <table className="content-table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nombre</th>
                  {width > 768 && <th>Correo</th>}
                  {width > 768 && <th>Teléfono</th>}
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {clientsToRender?.map(
                  (client, index) =>
                    client.rolId._id === roles.client && (
                      <>
                        <tr key={client._id}>
                          <td
                            style={
                              !client?.status ? { color: 'red' } : undefined
                            }
                          >
                            {index + 1}
                          </td>
                          <td
                            style={
                              !client?.status ? { color: 'red' } : undefined
                            }
                          >
                            {client.name}
                          </td>
                          {width > 768 && (
                            <td
                              style={
                                !client?.status ? { color: 'red' } : undefined
                              }
                            >
                              {client.email}
                            </td>
                          )}
                          {width > 768 && (
                            <td
                              style={
                                !client?.status ? { color: 'red' } : undefined
                              }
                            >
                              {client.number}
                            </td>
                          )}
                          <td>
                            <div className="iconsWrapper">
                              <button
                                disabled={!client?.status}
                                style={
                                  !client?.status
                                    ? { backgroundColor: 'gray' }
                                    : undefined
                                }
                                className="iconButton sendButton"
                                onClick={() => handleOpenEditModal(client)}
                              >
                                <span>
                                  <Icon name="edit" />
                                </span>
                              </button>
                              <button
                                disabled={!client?.status}
                                style={
                                  !client?.status
                                    ? { backgroundColor: 'gray' }
                                    : undefined
                                }
                                className="iconButton deleteButton"
                                onClick={() => setClientToDelete(client._id)}
                              >
                                <span>
                                  <Icon name="delete" />
                                </span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                )}
              </tbody>
            </table>
          )}
        </div>
        {isModalOpen && (
          <AppModal onClose={handleCloseModal}>
            <Form
              editModal={editModal}
              onSubmit={
                selectedClientData._id ? handleEdit : handleCreateClient
              }
              isLoading={
                isLoading && (status === 'adding' || status === 'updating')
              }
              clientData={selectedClientData}
            />
          </AppModal>
        )}
        {Boolean(clientToDelete) && (
          <Alert
            title="Estás seguro que quieres eliminar este cliente?"
            description="Esta acción es irreversible"
            type="delete"
            onCloseAlert={() => setClientToDelete('')}
            primaryAction={handleDeleteClient}
            isLoading={isLoading && status === 'deleting'}
          />
        )}
      </div>
    </div>
  );
}

export default Clients;
