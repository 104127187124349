/* eslint-disable */
export const getOrdersGraph = (params = null) => {
  return {
    query: `
    query Query($orderId: String, $vehicleId: String, $clientId: String, $sellerId: String, $startDate: String, $endDate: String) {
      Order(id: $orderId, vehicleId: $vehicleId, clientId: $clientId, sellerId: $sellerId, startDate: $startDate, endDate: $endDate) {
        isSuccess
        message
        result {
          driverId {
            _id
            idDocument
            license
            name
            number
          }
          amount
          amountDescription
          clientId {
            address
            email
            idDocument
            license
            name
            number
            status
            passport
            residenceAddress
            _id
          }
          dateTime
          deposit
          endDate
          sellerId {
            _id
            idDocument
            name
            number
            status
          }
          startDate
          vehicleId {
            _id
            brand
            circulationCard
            color
            deductible
            fuelId {
              name
            }
            idCar
            imgCar
            insurance
            is4x4
            model
            nAttendance
            nPolicy
            price
            priceOwner
            typeVehicle {
              _id
              name
            }
            year
            amountDoor
            amountSeat
            transmissionId {
              _id
              name
            }
            vehicleOwner {
              _id
              name
              email
              rolId {
                _id
                name
                rol
              }
              number
              license
              passport
              idDocument
              residenceAddress
              address
            }
          }
          hasAlreadyReturn
          pdfReference
          _id
        }
        error
      }
    }`,
    ...(params && { variables: params })
  };
};
