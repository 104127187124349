/* eslint-disable */
export const getVehicleGraph = (params = null) => {
  return {
    query: `
      query Vehicle($vehicleId: String, $idCar: String, $model: String, $brand: String, $color: String, $fuelId: String, $transmissionId: String, $year: Int, $is4X4: Boolean, $circulationCard: String) {
        Vehicle(id: $vehicleId, idCar: $idCar, model: $model, brand: $brand, color: $color, fuelId: $fuelId, transmissionId: $transmissionId, year: $year, is4x4: $is4X4, circulationCard: $circulationCard) {
          isSuccess
          message
          result {
            _id
            idCar
            brand
            model
            color
            year
            price
            imgCar
            amountDoor
            amountSeat
            priceOwner
            is4x4
            typeVehicle {
              _id
              name
            }
            insurance
            deductible
            nPolicy
            nAttendance
            fuelId {
              _id
              name
            }
            transmissionId {
              _id
              name
            }
            circulationCard
            vehicleOwner {
              _id
              name
              email
              rolId {
                _id
              }
              number
            }
          }
          error
        }
      }`,
    ...(params && { variables: params })
  };
};
