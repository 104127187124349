import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../Alert';
import Form from './Form';
import Icon from '../Icons';
import AppModal from '../Modal';
import Navbar from '../navbar';

import { toast } from 'react-toastify';

import '../../styles/sellers.css';
import Loader from '../Loader';
import { roles } from '../../constants/system';
import {
  addUser,
  closeModal,
  deleteUser,
  fetchUsers,
  openModal,
  updateUser
} from '../../redux/usersSlice';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Sellers() {
  const userData = JSON.parse(localStorage.getItem('user'));
  const users = useSelector((state) => state.user.users);
  const systemUsers = users?.filter(
    (user) =>
      user.rolId?._id === roles.seller || user.rolId?._id === roles.admin
  );
  const isModalOpen = useSelector((state) => state.user.isModalOpen);
  const isLoading = useSelector((state) => state.user.isLoading);
  const status = useSelector((state) => state.user.status);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [selectedUserData, setSelectedUserData] = useState({
    _id: '',
    name: '',
    email: '',
    number: '',
    password: '',
    rolId: roles.seller
  });
  const [userToDelete, setUserToDelete] = useState('');
  const [usersFiltered, setUsersFiltered] = useState([]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleOpenModal = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOpenCreateSellerModal = () => {
    setSelectedUserData({
      _id: '',
      name: '',
      email: '',
      number: '',
      password: '',
      rolId: roles.seller
    });
    handleOpenModal();
  };

  const handleOpenEditModal = (data) => {
    setSelectedUserData(data);
    handleOpenModal();
  };

  const handleEdit = (sellerDetails) => {
    dispatch(updateUser(sellerDetails));
  };

  const handleCreateSeller = (sellerDetails) => {
    dispatch(addUser(sellerDetails));
  };

  const handleDeleteSeller = () => {
    dispatch(deleteUser({ userId: userToDelete }));
    setUserToDelete('');
  };

  const handleSearch = (event) => {
    const searchedText = event.target?.value;
    const searchedClients = systemUsers?.filter(
      (seller) =>
        seller?.name.toLowerCase().includes(searchedText.toLowerCase()) ||
        seller?.email.toLowerCase().includes(searchedText.toLowerCase()) ||
        seller?.number.toLowerCase().includes(searchedText.toLowerCase())
    );

    setUsersFiltered(searchedClients);
  };

  const usersToRender = usersFiltered.length > 0 ? usersFiltered : systemUsers;

  if (isLoading && status === 'fetching') {
    return (
      <div className="container LoaderPageContainer">
        <div className="wrapper">
          <div className="loaderWrapper">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page_container">
      <Navbar />
      <div className="container">
        <div className="wrapper">
          <div className="titleWrapper">
            <h1>Lista de usuarios</h1>
          </div>
          {userData?.rolId === roles.admin && (
            <div className="createButtonWrapper">
              <button
                className="button"
                style={{ marginBottom: '1rem' }}
                onClick={handleOpenCreateSellerModal}
              >
                Añadir usuario
              </button>
            </div>
          )}

          <input
            type="text"
            id="name"
            name="name"
            className="input searchInput"
            placeholder="Busca por nombre, email, o teléfono"
            onChange={handleSearch}
          />

          {!isLoading && status === 'success' && usersToRender.length === 0 ? (
            <h2 className="heading" style={{ width: '100%' }}>
              No se encontraron usuarios
            </h2>
          ) : (
            <table className="content-table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  {width > 768 && <th>Correo</th>}
                  {width > 768 && <th>Teléfono</th>}
                  <th>Rol</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {usersToRender?.map(
                  (user, index) =>
                    (user.rolId._id === roles.seller ||
                      user.rolId._id === roles.admin) && (
                      <>
                        <tr key={user._id}>
                          <td
                            style={!user?.status ? { color: 'red' } : undefined}
                          >
                            {user.name}
                          </td>
                          {width > 768 && (
                            <td
                              style={
                                !user?.status ? { color: 'red' } : undefined
                              }
                            >
                              {user.email}
                            </td>
                          )}
                          {width > 768 && (
                            <td
                              style={
                                !user?.status ? { color: 'red' } : undefined
                              }
                            >
                              {user.number}
                            </td>
                          )}
                          <td
                            style={!user?.status ? { color: 'red' } : undefined}
                          >
                            {user.rolId._id === roles.seller
                              ? 'Vendedor'
                              : 'Admin'}
                          </td>
                          <td>
                            <div className="iconsWrapper">
                              <button
                                disabled={!user?.status}
                                style={
                                  !user?.status
                                    ? { backgroundColor: 'gray' }
                                    : undefined
                                }
                                className="iconButton sendButton"
                                onClick={() => handleOpenEditModal(user)}
                              >
                                <span>
                                  <Icon name="edit" />
                                </span>
                              </button>
                              <button
                                disabled={!user?.status}
                                style={
                                  !user?.status
                                    ? { backgroundColor: 'gray' }
                                    : undefined
                                }
                                className="iconButton deleteButton"
                                onClick={() => setUserToDelete(user._id)}
                              >
                                <span>
                                  <Icon name="delete" />
                                </span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                )}
              </tbody>
            </table>
          )}
        </div>
        {isModalOpen && (
          <AppModal onClose={handleCloseModal}>
            <Form
              onSubmit={selectedUserData._id ? handleEdit : handleCreateSeller}
              isLoading={
                isLoading && (status === 'adding' || status === 'updating')
              }
              userData={selectedUserData}
            />
          </AppModal>
        )}
        {Boolean(userToDelete) && (
          <Alert
            title="Estás seguro que quieres eliminar este vendedor?"
            description="Esta acción es irreversible"
            type="delete"
            onCloseAlert={() => setUserToDelete('')}
            primaryAction={handleDeleteSeller}
            isLoading={isLoading && status === 'deleting'}
          />
        )}
      </div>
    </div>
  );
}

export default Sellers;
